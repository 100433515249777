import React from "react";
import styles from "./chinhsach.module.css";

const ChinhSach = () => {
  return (
    <>
      <div className={styles.chinhSach__header}>
        <div className={styles.header__layer}></div>
        <h2>Chính sách</h2>
      </div>
      <div className={styles.chinhSach}>
        <div className={styles.chinhSach__body}>
          <div>
            <h4 className="text-center">CHÍNH SÁCH DÀNH CHO CỘNG TÁC VIÊN</h4>
            <div className={styles.chinhSach__note}>
              <p>
                Cộng tác viên (CTV) là người thực hiện công việc tìm kiếm, xây
                dựng, chăm sóc lượng khách hàng của mình kiếm được.
              </p>
              <p>Tất cả đơn hàng phát sinh từ CTV:</p>
              <ul>
                <li>
                  - Công ty sẽ chịu trách nhiệm soạn hàng, giao hàng, bảo
                  hành... cho khách hàng của CTV.
                </li>
                <li>
                  - Khách hàng của CTV là trọn đời và không thay đổi dưới bất kì
                  hình thức nào.
                </li>
              </ul>
            </div>
            <div>
              <h5>1.1. Điều kiện trở thành CTV</h5>
              <p>
                - Dành cho tất cả mọi người muốn kiếm thêm thu nhập, ưu tiên
                những người có kinh nghiệm và yêu thích công việc kinh doanh,
                quảng cáo, tư vấn online hoặc trực tiếp,...
              </p>
              {/* <p>
                - Không cần bất cứ điều kiện ràng buộc nào, công ty hỗ trợ về
                nền tảng kinh doanh cho bất kỳ đối tượng nào, có thể bắt đầu từ
                0đ vẫn có thể kinh doanh cùng KTV.
              </p> */}
            </div>
            <div>
              <h5>1.2. Quyền lợi</h5>
              <p>
                - Công việc đơn giản: CTV chỉ cần giới thiệu khách đến với Công
                ty và thanh toán đơn hàng thành công. Mọi việc khác như giao
                hàng, vận chuyển sẽ do Công ty đảm nhận.
              </p>
              <p>
                - Đối tác uy tín: Công ty Cổ phần Tập đoàn Khỏe Đẹp Hạnh Phúc
                đảm bảo thanh toán đầy đủ, chính xác các khoản doanh thu của CTV
                ngay khi đơn hàng của khách đặt hàng thành công.
              </p>
              <p>
                - Thông tin minh bạch từ sản phẩm cho đến doanh thu của CTV,
                bằng kiến thức toán học cơ bản, CTV có thể dễ dàng tự tính mức
                thưởng mình nhận được đối với mỗi đơn hàng thành công.
              </p>
              <p>
                - Không đầu tư, không cần bỏ vốn ôm hàng, giá trị đơn hàng tùy
                chọn theo nhu cầu, tránh được rủi ro mất vốn, tồn kho hàng
                hóa,... không cần bận tâm về các khâu giao hàng, thu tiền, nguồn
                hàng, sản phẩm,...
              </p>
              <p>
                - Không áp lực về doanh số bán hàng, số lượng đơn hàng bao nhiêu
                hưởng lợi nhuận bấy nhiêu, CTV sẽ được hưởng các mức hoàn tiền
                theo công thức hoàn tiền giành cho CTV..
              </p>
              <p>
                - Khách hàng của CTV là trọn đời, mỗi lần khách của mình mua
                hàng, Cộng tác viên được hưởng các mức hoàn tiền theo công thức
                hoàn tiền giành cho CTV..
              </p>
              <p>
                - Hỗ trợ truyền thông và marketing miễn phí: các Cộng tác viên
                được toàn quyền sử dụng hình ảnh, video, thông tin mà Công ty Cổ
                phần Tập đoàn Khỏe Đẹp Hạnh Phúc đăng tải trên website chính
                thức (zalo, youtube, facebook,...)
              </p>
              <p>
                - Không cần phải ký quỹ hay thủ tục giấy tờ gì để hưởng doanh
                thu/ưu đãi.
              </p>
              <p>
                - Tính thanh khoản nhanh, đầy đủ và kịp thời, không hạn chế hạn
                mức thanh khoản về quyền lợi của CTV khi có nguồn thu nhập trong
                tài khoản của mình.
              </p>
              <p>
                - Sản phẩm của Công ty luôn rõ nguồn gốc xuất xứ, đảm bảo uy tín
                và chất lượng, cam kết cộng tác với CTV theo quan hệ win-win,
                tức đôi bên cùng đồng hành phát triển, đôi bên cùng có lợi. Hơn
                nữa, những sản phẩm của Công ty chủ yếu là hàng tiêu dùng sử
                dụng thường ngày, tạo điều kiện cho Cộng tác viên dễ dàng kiếm
                được lượng khách trung thành, từ đó xây dựng nên nguồn thu nhập
                ổn định cho chính mình.
              </p>
              <p>
                - Mang lại lợi ích thiết thực cho khách hàng của Cộng tác viên:
                tiết kiệm chi phí, thời gian, tạo cơ hội tiếp cận sản phẩm chính
                hãng, chất lượng cao,...
              </p>
              <p>
                - Công ty cung cấp toàn bộ hồ sơ pháp lý về sản phẩm (giấy đăng
                ký sản phẩm, giấy phép lưu hành,...)
              </p>
            </div>
            <div>
              <h5>1.3. Mức thưởng</h5>
              <p>
                Với mỗi đơn hàng của khách do Cộng tác viên giới thiệu ra, Cộng
                tác viên sẽ được hưởng các mức hoàn tiền theo công thức hoàn
                tiền giành cho cộng tác viên. Số tiền này được trả dưới dạng
                điểm tri ân Cộng tác viên đã có công giới thiệu khách đến với
                Công ty, còn gọi là điểm KTV. 1 điểm KTV tương đương 1.000 VNĐ.
              </p>
            </div>
            <div>
              <h5>1.4. Đặt hàng và giao hàng</h5>
              <p>
                - Đầu tiên, Cộng tác viên tạo cho mình một tài khoản trên
                website{" "}
                <a href="https://khoedephanhphuc.com.vn">
                  https://khoedephanhphuc.com.vn
                </a>
              </p>
              <p>
                - Để đăng ký khách hàng cho mình, Cộng tác viên gửi đường link
                của mình hoặc cung cấp số điện thoại của mình tới khách và hướng
                dẫn khách đặt hàng tạo tài khoản. Chỉ như vậy, sau khi tài khoản
                của khách tạo thành công, khách hàng này đã được đăng ký sẵn là
                của bạn.
              </p>
              <p>
                - Cộng tác viên hỗ trợ khách những quy trình như nạp tiền, tạo
                đơn hàng và thanh toán trên website{" "}
                <a href="https://khoedephanhphuc.com.vn">
                  https://khoedephanhphuc.com.vn
                </a>
                . Cộng tác viên không cần gửi bất kì thông tin nào về khách hoặc
                đơn hàng cho Công ty, website của chúng tôi sẽ tự động phân loại
                và tính doanh số chính xác cho từng tài khoản Cộng tác viên.
              </p>
              <p>
                - Sau khi đơn hàng hoàn tất thanh toán, Công ty hoặc các Đại lý
                ủy quyền sẽ đảm nhận việc giao hàng tới tận tay khách hàng theo
                thông tin đã cung cấp trong đơn, Cộng tác viên không phải tham
                gia vào quy trình này.
              </p>
            </div>
            <div>
              <h5>1.5. Thủ tục đăng ký trở thành Cộng tác viên</h5>
              <p>
                Không cần thủ tục giấy tờ, mọi việc bạn cần làm chỉ là tạo cho
                mình 1 tài khoản trên website{" "}
                <a href="https://khoedephanhphuc.com.vn">
                  https://khoedephanhphuc.com.vn
                </a>{" "}
                và cập nhật chính xác, đầy đủ thông tin cá nhân. Khi này bạn đã
                chính thức trở thành 1 Cộng tác viên của Công ty Cổ phần Tập
                đoàn Khỏe Đẹp Hạnh Phúc.
              </p>
              <p>
                *Lưu ý: Mỗi CMND/CCCD chỉ được đăng ký 01 tài khoản KTV (tương
                ứng 01 mã số kinh doanh), khi xác minh KYC những trường hợp
                trùng CMND/CCCD sẽ được hệ thống tự động thông báo không hợp lệ
                và không được xác minh thành công.
              </p>
              <p>
                Nếu cần hỗ trợ, tìm hiểu thêm về quy trình làm việc, chính sách
                hay những sản phẩm cụ thể cũng như tài liệu, tư liệu,... Cộng
                tác viên có thể liên hệ với hotline của Công ty: 039.686.5179
                hoặc đặt câu hỏi trên nhóm Zalo/Facebook,...
              </p>
            </div>
          </div>
          <div>
            <h4 className="text-center mt-5">CHÍNH SÁCH GIAO HÀNG</h4>
            <div>
              <p>
                Công ty Cổ phần Tập đoàn Khỏe Đẹp Hạnh Phúc có dịch vụ giao hàng
                tận nơi trên toàn quốc, áp dụng cho toàn bộ khách hàng đặt mua
                sản phẩm trên website{" "}
                <a href="https://khoedephanhphuc.com.vn">
                  https://khoedephanhphuc.com.vn
                </a>
              </p>
              <p>
                Đơn hàng sẽ được chuyển phát đến chính xác địa chỉ khách hàng
                cung cấp thông qua công ty vận chuyển trung gian.
              </p>
              <h6>QUY TRÌNH GIAO HÀNG</h6>
              <p>
                - Sau khi nhận được đơn quý khách đặt tại website{" "}
                <a href="https://khoedephanhphuc.com.vn">
                  https://khoedephanhphuc.com.vn
                </a>{" "}
                , Công ty sẽ xác nhận giao hàng đồng thời gửi tin nhắn thông báo
                tới số thuê bao của quý khách, trong đó bao gồm mã đơn hàng quý
                khách đã đặt, từ mã này quý khách có thể kiểm tra hoặc cập nhật
                thông tin đơn hàng trên website{" "}
                <a href="https://khoedephanhphuc.com.vn">
                  https://khoedephanhphuc.com.vn
                </a>
              </p>
              <p>
                - Công ty sẽ không tiến hành gọi điện xác nhận đơn hàng để tránh
                làm phiền quý khách (trừ trường hợp phát sinh vấn đề cần quý
                khách phải trực tiếp giải quyết).
              </p>
              <p>
                Lưu ý: Trường hợp khách hàng bận việc đột xuất không thể nhận
                hàng, Công ty có thể sẽ hỗ trợ phát tiếp đơn hàng khi đơn vị vận
                chuyển (bên thứ 3) vẫn còn giữ đơn hàng. Nếu đơn hàng đã trả về
                đại lý hoặc công ty, lần vận chuyển sau khách hàng sẽ phải trả
                phí (kể cả khi đơn này trước đây đạt đủ điều kiện miễn phí vận
                chuyển).
              </p>
              <p>
                - Đơn hàng của quý khách sẽ được đóng và gửi trong ngày nếu đơn
                hàng hoàn tất thanh toán trước 15h00, đơn hàng đặt sau 15h00
                hoặc đặt trong ngày Chủ nhật/ngày lễ sẽ được thực hiện vào ngày
                làm việc gần nhất.
              </p>
              <h6>QUY ƯỚC CHUNG ĐỐI VỚI KHÁCH HÀNG</h6>
              <p>
                1. Khách hàng có trách nhiệm cung cấp chính xác thông tin người
                nhận hàng (Họ tên, điện thoại liên hệ) và địa chỉ giao hàng, nếu
                xảy ra sai sót do thông tin khách hàng cung cấp thiếu chính xác,
                Công ty sẽ không chịu trách nhiệm xử lý.
              </p>
              <p>
                2. Thời gian nhận được hàng phụ thuộc vào đơn vị vận chuyển.
                Trong trường hợp chậm trễ hơn so với thời gian dự kiến, công ty
                sẽ có trách nhiệm điều tra nguyên nhân và tìm giải pháp xử lý.
              </p>
              <p>
                3. Đơn hàng dưới 3.300.000 VNĐ khách hàng sẽ trả phí, đơn hàng
                từ 3.300.000 VNĐ trở lên khách hàng sẽ được Công ty hỗ trợ toàn
                bộ phí vận chuyển.
              </p>
              <p>
                4. Nếu 12 tháng liên tục khách hàng không phát sinh đơn hàng mới thì mã số khách hàng sẽ bị xoá khỏi hệ thống. Khi khách hàng có nhu cầu mua hàng, sẽ đăng ký lại mã số khách hàng mới.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChinhSach;
